import dynamic from 'next/dynamic';
import {FC, useCallback} from 'react';
import {useCookies} from 'react-cookie';
import {COOKIE_CONSENT_COOKIE} from '@/constants/cookies';
import {IS_PRODUCTION} from '@/constants/global';
import {useAuthPath, useHandleSetCookies, useUser} from '@/hooks';
import {useRemoveAllCookies} from '@/hooks/useRemoveAllCookies';
import {useRouter} from '@/hooks/useRouter';

const CookiePolicyModal = dynamic(() => import('./CookiePolicyModal'));

export const COOKIE_CONSENT_ERROR =
  'Cookies are disabled. Please enable cookies and try again';

interface CookieConsentProps {
  isModalActive: boolean;
  toggleModal: () => void;
  onSave?: () => void;
}

const CookieConsent: FC<CookieConsentProps> = ({
  isModalActive,
  toggleModal,
  onSave,
}) => {
  const [cookies] = useCookies();
  const currentConsent = JSON.parse(cookies[COOKIE_CONSENT_COOKIE] || 'true');
  const {logged} = useUser();
  const {logoutPath} = useAuthPath();
  const {handleSetCookie} = useHandleSetCookies();
  const router = useRouter();
  const {removeAllCookies} = useRemoveAllCookies();

  const handleConsent = useCallback(() => {
    handleSetCookie(COOKIE_CONSENT_COOKIE, true, {
      maxAge: 24 * 60 * 60,
      path: '/',
    });
  }, [handleSetCookie]);

  const handleNonConsent = useCallback(() => {
    removeAllCookies();
    if (IS_PRODUCTION) {
      //@ts-ignore
      window.clarity?.('consent', false);
    }
    handleSetCookie(COOKIE_CONSENT_COOKIE, false, {
      maxAge: 24 * 60 * 60,
      path: '/',
    });
    if (logged) {
      router.push(logoutPath);
    }
  }, [removeAllCookies, handleSetCookie, logged, router, logoutPath]);

  const handleCookieConsent = useCallback(
    (consent: boolean) => (consent ? handleConsent() : handleNonConsent()),
    [handleConsent, handleNonConsent],
  );

  const handleModalSave = useCallback(
    (consent: boolean) => {
      toggleModal();
      handleCookieConsent(consent);
      onSave && onSave();
      window.location.reload();
    },
    [toggleModal, handleCookieConsent, onSave],
  );

  return isModalActive ? (
    <CookiePolicyModal
      isOpen={isModalActive}
      handleCookieConsent={handleModalSave}
      currentConsent={currentConsent}
      closeModal={toggleModal}
    />
  ) : null;
};

export default CookieConsent;
